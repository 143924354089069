.home {
  .img-area {
    background: url("../Image/home-main-img.png") no-repeat fixed center center /
      cover;
    height: 862px;
    .contents-bx {
      color: #fff;
      font-size: 26px;
      .first-line {
        font-size: 36px;
        line-height: 1.7;
        img {
          width: 172px;
          margin: -4px 0 0 20px;
        }
      }
      p:nth-child(2) {
        margin: 10px 0 6px;
      }
    }
  }

  .lawsuit-type-area {
    padding: 90px 0 20px;
    > p {
      margin: 0 0 50px 0;
      font-size: 30px;
    }
    .lawsuit-type-group {
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      .lawsuit-type-bx {
        width: 180px;
        height: 180px;
        margin: 0 30px 30px;
        border: 1px solid #e5e5e5;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        img {
          margin: 0 0 20px 0;
        }
        p {
          font-size: 18px;
          color: #222;
        }
      }
      .lawsuit-type-bx {
        img {
          width: 60px;
        }
      }
    }
  }

  .user-count-area {
    padding: 0 0 90px 0;
    p {
      font-size: 30px;
    }
    b {
      font-size: 50px;
    }
  }

  .card-view-area {
    background-color: #f7f7f7;
    padding: 80px 0;
    .top-bx {
      margin: 0 0 50px 0;
      .btn-group {
        button {
          font-size: 20px;
          color: #c1c1c1;
          cursor: pointer;
        }
        button:not(:last-child) {
          margin: 0 50px 0 0;
        }
        button.selected {
          color: #222;
        }
      }
    }

    .review {
    }

    .faq {
    }
  }

  .video-area {
    padding: 100px 0;
    .video-group {
      .video-bx-wrap {
        width: 50%;
        padding-top: calc(50% * 2 / 3);
        position: relative;
        .video-bx {
          width: calc(100% - 20px);
          height: 100%;
          cursor: pointer;
          position: absolute;
          top: 0;
          img {
            height: 100%;
          }
          .video-info-bx {
            width: 100%;
            margin: auto 0 0 0;
            padding: 30px;
            position: absolute;
            bottom: 0;
            .txt-bx {
              .video-ttl {
                margin: 0 0 6px 0;
                color: #fff;
                font-size: 18px;
              }
              .video-txt {
                color: #bdc1c2;
              }
            }
            button {
              width: 54px;
              color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              .MuiSvgIcon-root {
                width: 46px;
                height: 46px;
              }
            }
          }
        }
      }
      .video-bx-wrap:nth-child(even) {
        .video-bx {
          right: 0;
        }
      }
      .video-bx-wrap:nth-child(odd) {
        .video-bx {
          left: 0;
        }
      }
    }
  }

  .video-modal{
    width: 100%;
    height: 100vh;
    padding: 20px 20px;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    position: fixed;
    top: 0;
    z-index: 5;
    .cont{ margin: auto; position: relative; }
    .MuiButton-root{
      width: 36px;
      height: 36px;
      border-radius: 50%;
      color: #fff;
      position: absolute;
      top: -6px;
      right: -36px;
    }
    iframe{
      width: 780px;
      height: 520px;
      margin: auto;
    }
  }
}
