.main {
  min-height: 100vh;

  header {
    width: 100%;
    min-width: 1280px;
    height: 80px;
    position: sticky;
    top: 0;
    z-index: 2;
    .mw1280 {
      height: 100%;
    }
    .logo-bx {
      height: 100%;
      img {
        width: 160px;
      }
    }

    nav {
      height: 100%;
      margin: 0 -20px 0 0;
      a,
      .sign-in-btn {
        margin: 0 10px;
        padding: 0 10px;
        font-size: 18px;
        color: rgba(255, 255, 255, 0.8);
        span {
          position: relative;
        }
        span:before {
          content: "";
          width: 0%;
          height: 3px;
          background-color: #fff;
          position: absolute;
          bottom: -6px;
          transition: all 0.3s ease;
        }
        span:after {
          content: attr(data-hover);
          color: #fff;
          max-width: 0%;
          overflow: hidden;
          position: absolute;
          top: 0;
          left: 0;
          transition: all 0.3s ease;
        }
      }
      a:hover,
      .sign-in-btn:hover {
        span:before {
          width: 100%;
          opacity: 1;
        }
        span:after {
          max-width: 100%;
        }
      }
      a.selected {
        color: #fff;
        font-weight: bold;
        span:before {
          width: 100%;
          opacity: 1;
        }
      }

      .account {
        position: relative;
        .account-btn {
          height: 100%;
          margin: 0 0 0 20px;
          padding: 0;
          color: #fff;
          font-size: 18px;
          .MuiSvgIcon-root.rotate {
            transform: rotate(180deg);
          }
        }

        ul {
          background-color: #fff;
          padding: 10px;
          border: 1px solid #bebebe;
          position: absolute;
          top: 60px;
          right: 0;
          li {
            padding: 0 10px;
            a,
            button {
              width: 100%;
              height: 40px;
              margin: 0;
              padding: 6px;
              color: #222;
              font-size: 16px;
            }
            a:hover,
            button:hover {
              font-weight: bold;
              background: transparent;
            }
          }
          li:not(:last-child) {
            margin: 0 0 6px;
          }
        }
        .MuiTouchRipple-root {
          display: none;
        }
      }
    }
  }
  .header-scroll {
    background-color: #6bbaff;
  }

  main {
    margin: -80px 0 0;
    .page-view {
      .h-top {
        height: 80px;
        background-color: #6bbaff;
      }
      .inner {
        padding: 80px 50px;
        .head-area {
          margin: 0 0 60px;
          text-align: center;
          .head {
            font-size: 24px;
            position: relative;
          }
          .head::after {
            content: "";
            width: 50%;
            height: 2px;
            background-color: #bebebe;
            position: absolute;
            bottom: -12px;
            left: 50%;
            transform: translate(-50%, 0);
          }
        }
      }
    }

    .txt-card-view-group {
      margin: 0 -10px;
      flex-wrap: wrap;
      .card-wrap {
        width: calc(100% * 1 / 3);
        margin: 0 0 40px;
        padding: 0 10px;
        .card {
          background-color: #fff;
          border: solid 1px #e5e5e5;
          padding: 30px;
          cursor: pointer;

          > .color-6bbaff {
            margin: 0 0 30px;
          }
          .ttl {
            margin: 0 0 36px;
            display: block;
            font-size: 18px;
          }
          .txt {
            height: 84px;
            color: #777;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
          }
          .date {
            margin: 40px 0 0 0;
            display: block;
            color: #777;
          }
        }
      }
    }

    .detail-page {
      .inner {
        max-width: 728px;
        margin: 0 auto;
        .ttl {
          margin: 30px 0 0;
          display: block;
          font-size: 18px;
        }
        .date {
          margin: 10px 0 40px;
          display: block;
          color: #777;
        }
        .txt {
          line-height: 2;
        }
      }
    }
  }

  footer {
    margin-top: auto;
    background-color: #1a1a1a;
    color: #fff;
    .term-area {
      padding: 32px 0;
      border-bottom: 2px solid #999;
      font-size: 18px;
      a:not(:last-child) {
        margin: 0 60px 0 0;
      }
    }
    .info-area {
      padding: 60px 0 36px;
      .logo-bx {
        width: 200px;
        margin: 0 100px 0 0;
      }
      .txt-bx{
        .row-bx{
          p + p{
            margin-left: 10px;
          }
        }
      }
    }
  }
}
