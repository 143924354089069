.introduce {
  .top-img-area {
    background: url("../Image/introduce-main-img.png") no-repeat fixed center
      center / cover;
    height: 600px;
    .contents-bx {
      p {
        color: #fff;
        font-size: 36px;
      }
      img {
        width: 240px;
        margin: 40px auto 0;
      }
    }
  }

  .img-txt-area {
    margin-bottom: 120px;
    .img-txt-group {
      .img-bx {
        width: 40%;
        margin: 0 50px 0 auto;
        position: relative;
        .back-square {
          width: 200px;
          height: 200px;
          background-color: #6bbaff;
          position: absolute;
          bottom: -24px;
          z-index: -1;
        }
        img {
          width: calc(100% - 24px);
          margin: 0 0 0 auto;
        }
      }
      .txt-bx {
        width: 50%;
        font-size: 16px;
        .first-line {
          margin: 0 0 28px 0;
          display: block;
          font-size: 20px;
        }
        .second-line {
          line-height: 1.75;
        }
        .third-line {
          margin: 40px 0 0 0;
          display: block;
          line-height: 1.75;
        }
        .fourth-line {
          line-height: 1.75;
        }
      }
    }
  }

  .card-view-area {
    padding: 0 0 100px 0;
    .logo-bx {
      margin: 0 0 70px 0;
      img {
        width: 200px;
        margin: 0 auto;
      }
    }

    .card-group {
      margin: 0 -10px;
      position: relative;
      .card-wrap {
        width: 33.33333%;
        padding: 0 10px;
        position: relative;
        .card {
          height: 100%;
          padding: 60px 10px 50px;
          border: solid 1px #e5e5e5;
          color: #6a6a6a;
          img {
            margin: 0 auto 30px;
          }
          .card-ttl {
            margin: 0 0 20px 0;
            display: block;
            font-size: 20px;
          }
          p {
            font-size: 16px;
            line-height: 1.8;
          }
        }
      }
      .card-wrap:nth-child(1) {
        img {
          width: 70px;
        }
      }
      .card-wrap:nth-child(2) {
        img {
          width: 56px;
        }
      }
      .card-wrap:nth-child(3) {
        img {
          width: 46px;
        }
      }
      .card-wrap:nth-child(1)::after,
      .card-wrap:nth-child(3)::after {
        content: "";
        width: 1px;
        height: 35px;
        background-color: #e5e5e5;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -100%);
      }
      .card-wrap:nth-child(2)::after {
        content: "";
        width: 1px;
        height: 70px;
        background-color: #e5e5e5;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -100%);
      }
    }
    .card-group::after {
      content: "";
      width: calc(100% * 2 / 3);
      height: 1px;
      background-color: #e5e5e5;
      position: absolute;
      top: -35px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  .txt-area {
    .txt-cont {
      display: inline-block;
      position: relative;
      p {
        font-size: 26px;
      }
      .MuiSvgIcon-root {
        width: 40px;
        height: 40px;
        color: #cfcfcf;
        position: absolute;
      }
      .MuiSvgIcon-root:nth-child(1) {
        top: -6px;
        left: -50px;
        transform: rotate(180deg);
      }
      .MuiSvgIcon-root:nth-child(3) {
        top: -6px;
        right: -50px;
      }
    }
  }

  .use-step-area {
    padding: 70px 0 120px;
    .use-group {
      margin: 0 -40px;
      position: relative;
      .card-wrap {
        width: 25%;
        padding: 0 40px;
        .card {
          .use-step-no {
            width: 112px;
            height: 30px;
            margin: 0 auto 20px;
            border-radius: 10px;
            background-color: #6bbaff;
            color: #fff;
            text-transform: uppercase;
            font-size: 20px;
          }
          .use-step-bx {
            padding: 0 0 20px 0;
            border: 1px solid #6bbaff;
            border-radius: 20px 20px 0 0;
            position: relative;
            .ttl {
              padding: 20px 0;
              border-bottom: 1px solid #6bbaff;
              display: block;
              font-size: 20px;
            }
            img {
              width: auto;
              height: 50px;
              margin: 20px auto;
            }
            p {
              font-size: 16px;
              color: #444;
            }
          }
          .use-step-bx::before {
            content: "";
            width: 2px;
            height: 21px;
            background-color: #6bbaff;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, -100%);
          }
        }
      }
    }
    .use-group::before {
      content: "";
      width: calc(100% - 80px);
      height: 1px;
      background-color: #e5e5e5;
      position: absolute;
      top: 14.5px;
      left: 40px;
      z-index: -1;
    }
  }

  .civil-litigation-area,
  .paymentOrder-area {
    padding: 100px 0;
    .procedure-ttl {
      font-size: 26px;
    }
    .procedure-group {
      margin: 0 -40px;
      .card-wrap {
        padding: 40px;
        position: relative;
        .card {
          .img-circle {
            width: 120px;
            height: 120px;
            margin: 0 auto 20px;
            border: 1px solid #e5e5e5;
            border-radius: 50%;
            background-color: #fff;
            img {
              width: auto;
              height: 60px;
            }
          }
          .ttl {
            margin: 0 0 14px 0;
            display: block;
            font-size: 18px;
            letter-spacing: 0px;
          }
          p {
            font-size: 16px;
            line-height: 24px;
          }
        }
        .MuiSvgIcon-root {
          color: #e5e5e5;
          font-size: 28px;
          position: absolute;
          top: 100px;
          right: 0;
          transform: translate(50%, -50%);
        }
      }
      .card-wrap:last-child {
        .MuiSvgIcon-root {
          display: none;
        }
      }
    }
  }

  .civil-litigation-area {
    background-color: #f7f7f7;
    .sj-head {
      margin: 0 0 60px 0;
    }
    .procedure-group {
      .card-wrap {
        width: 20%;
      }
    }
  }

  .paymentOrder-area {
    .procedure-group {
      .card-wrap {
        width: 25%;
      }
    }
  }
}
