.expense {
  .top-img-area {
    background: url("../Image/expense-main-img.png") no-repeat fixed center
      center / cover;
    height: 600px;
    color: #fff;
    .contents-bx {
      b {
        margin: 0 0 20px 0;
        display: inline-block;
        font-size: 36px;
      }
      p {
        font-size: 30px;
      }
    }
  }

  .card-view-area {
    padding: 0 0 100px 0;
    .card-group {
      margin: 0 -10px;
      .card-wrap {
        padding: 0 10px;
        .card {
          width: 400px;
          padding: 60px;
          border: solid 1px #e5e5e5;
          text-align: center;
          .card-ttl-bx {
            height: 40px;
            margin: 0 0 30px 0;
            border-radius: 25px;
            background-color: #6bbaff;
            color: #fff;
            font-size: 24px;
            line-height: 40px;
          }
          img {
            width: auto;
            height: 80px;
            margin: 0 auto;
          }
          .price {
            margin: 45px 0 50px;
            font-size: 26px;
            b {
              font-size: 40px;
            }
          }
          .sub-txt {
            color: #444;
            font-size: 16px;
          }
          .sub-txt:nth-child(4) {
            margin: 0 0 10px 0;
          }
          .color-6bbaff {
            font-size: 16px;
          }
        }
      }
    }
  }

  .txt-area {
    padding: 0 0 60px 0;
    .txt-cont {
      display: inline-block;
      position: relative;
      p {
        font-size: 24px;
      }
      .MuiSvgIcon-root {
        width: 40px;
        height: 40px;
        color: #cfcfcf;
        position: absolute;
      }
      .MuiSvgIcon-root:nth-child(1) {
        top: -10px;
        left: -50px;
        transform: rotate(180deg);
      }
      .MuiSvgIcon-root:nth-child(4) {
        right: -50px;
        bottom: -10px;
      }
    }
  }
}
