// #6bbaff
// [z-index] header: 2 / modal: 5

@font-face {
  font-family: "S-CoreDream-4Regular";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-4Regular.woff")
    format("woff");
}

@font-face {
  font-family: "S-CoreDream-6Bold";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_six@1.2/S-CoreDream-6Bold.woff")
    format("woff");
}

* {
  margin: 0;
  box-sizing: border-box;
  letter-spacing: 0.5px;
  line-height: 1.5;
  font-family: "S-CoreDream-4Regular";
  word-break: keep-all;
}
::selection {
  background-color: rgba(107, 186, 255, 0.6);
}

body {
  min-width: 1280px;
  font-size: 14px;
  color: #222;
}

a {
  color: inherit;
  display: block;
  text-decoration: none;
  cursor: pointer;
}
a:hover {
  text-decoration: none !important;
}
.MuiLink-root {
  text-decoration: none !important;
}
button {
  background-color: transparent;
  border: none;
  font-family: "S-CoreDream-4Regular" !important;
}
img {
  width: 100%;
  display: block;
  object-fit: cover;
}
ul {
  padding: 0;
  list-style: none;
}
input {
  font-size: 14px !important;
}

.no_scroll {
  overflow: hidden;
}

.mw1280 {
  max-width: 1280px;
  padding: 0 50px;
  margin: 0 auto;
}
.fs12 {
  font-size: 12px;
}
.fs13 {
  font-size: 13px;
}
.fs14 {
  font-size: 14px;
}
.fs20 {
  font-size: 20px;
}
.bold {
  font-family: "S-CoreDream-6Bold";
}
.ta-center {
  text-align: center;
}

label {
  cursor: pointer;
}

.flex {
  display: flex;
}
.flex1 {
  display: flex;
  flex: 1;
}
.wrap {
  flex-wrap: wrap;
}
.ai-center {
  align-items: center;
}
.ai-start {
  align-items: flex-start;
}
.jc-center {
  justify-content: center;
}
.jc-sb {
  justify-content: space-between;
}
.column {
  flex-direction: column;
}
.row {
  flex-direction: row;
}
.flex-row {
  display: flex;
  align-items: center;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.color-6bbaff {
  color: #6bbaff;
}
.color-222 {
  color: #222;
}
.color-999 {
  color: #999;
}

.contained {
  min-height: 36px;
  border-radius: 6px;
  background-color: #6bbaff !important;
  color: #fff !important;
}
.contained:hover {
  background-color: #158efb !important;
}
.outlined {
  border: 1px solid #6bbaff !important;
  background-color: #fff !important;
  border-radius: 6px;
  color: #6bbaff !important;
}

.add-btn {
  width: 30px;
  height: 30px;
  border-radius: 50% !important;
  background-color: #6bbaff !important;
  color: #fff !important;
}
.remove-btn {
  width: 30px;
  height: 30px;
  border-radius: 50% !important;
  background-color: #6bbaff !important;
  color: #fff !important;
}

.bx-shadow {
  box-shadow: 0px 0px 10px 1px #ccc;
}

.scroll-none {
  overflow: hidden;
}

.sj-head {
  margin: 100px 0 60px 0;
  color: #222;
  font-size: 30px;
  text-align: center;
  position: relative;
}
.sj-head::after {
  content: "";
  width: 42px;
  height: 1px;
  background-color: #222;
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
}

/* Button */
.MuiButton-root {
  min-width: 0 !important;
}

/* radio, checkbox */
.MuiRadio-root,
.MuiCheckbox-root {
  margin: 0 0 0 -5px !important;
  padding: 5px !important;
  .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
  }
}
.MuiRadio-root.Mui-checked,
.MuiCheckbox-root.Mui-checked {
  color: #6bbaff !important;
}

/* input-text */
.MuiTextField-root {
  height: 30px;
  border-radius: 4px;
  background-color: #fff;
  .MuiInputBase-root {
    width: 100%;
  }
  .MuiOutlinedInput-input {
    width: 100%;
    height: 30px;
    padding: 0 10px !important;
  }
  fieldset {
    height: 30px;
    top: 0;
    legend {
      display: none;
    }
  }
  .MuiInputLabel-outlined {
    display: none;
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #6bbaff !important;
  }
  .MuiFormLabel-root.Mui-focused {
    color: #6bbaff !important;
  }
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.MuiOutlinedInput-input.Mui-disabled {
  -webkit-text-fill-color: #222 !important;
}

/* select */
.MuiFormControl-root.select {
  height: 30px;
  .MuiInputBase-root {
    height: 100%;
    background-color: #fff;
    .MuiSelect-select {
      height: 30px;
      padding: 0 30px 0 10px;
      display: flex;
      align-items: center;
    }
    .MuiSelect-icon {
      right: 0;
    }
    fieldset {
      height: 30px;
      top: 0;
      legend {
        display: none;
      }
    }
  }
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #6bbaff !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #6bbaff !important;
}

/* Date */
.date-picker, .date-time-picker {
  .MuiTextField-root{
    width: 100%;
  }
  .MuiOutlinedInput-root {
    padding: 0;
    .MuiOutlinedInput-input {
      padding: 0 0 0 10px !important;
    }
  }
  .MuiInputAdornment-root {
    margin: 0;
    .MuiButtonBase-root {
      margin: 0;
      padding: 5px;
      .MuiSvgIcon-root {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.date-picker{
  width: 120px;
}
.date-time-picker{
  width: 190px;
}
// .MuiFormControl-marginNormal {
//   height: 30px;
//   margin: 0 !important;

//   .MuiInputBase-root {
//     width: 140px;
//     height: 30px;
//     padding: 0;
//     border: 1px solid rgba(0, 0, 0, 0.23);
//     border-radius: 4px;
//     input {
//       height: 30px;
//       padding: 0;
//       border-radius: 4px 0 0 4px;
//     }
//     .MuiInputAdornment-root {
//       margin: 0;
//       .MuiIconButton-root {
//         width: 32px;
//         height: 30px;
//         padding: 0;
//         .MuiSvgIcon-root {
//           width: 20px;
//           height: 20px;
//         }
//       }
//     }
//   }

//   .MuiInput-underline:before,
//   .MuiInput-underline.Mui-error:after {
//     display: none;
//   }

//   .MuiInput-underline:hover:not(.Mui-disabled):before,
//   .MuiInput-underline:after {
//     border-bottom: 2px solid #6bbaff;
//   }
//   .MuiFormHelperText-root.Mui-error {
//     display: none;
//   }
// }

.loading-bx {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 7;

  .MuiCircularProgress-root {
    width: 60px !important;
    height: 60px !important;
    color: #6bbaff;
  }
}
