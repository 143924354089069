// common
.user {
  .head-area {
    margin: 0 0 60px;
    text-align: center;
    .head {
      font-size: 24px;
      position: relative;
    }
    .head::after {
      content: "";
      width: 50%;
      height: 2px;
      background-color: #bebebe;
      position: absolute;
      bottom: -12px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  .flex-row {
    .ttl {
      color: #777;
    }
  }

  .MuiTextField-root {
    width: 240px;
    display: block;
    .MuiInputBase-root {
      height: 100%;
    }
    .MuiOutlinedInput-input {
      height: 100%;
    }
    fieldset {
      height: 100%;
    }
  }

  .alert-txt {
    color: #6bbaff;
  }
  .alert-txt.incorrect {
    color: #ea4335;
  }

  // select
  .MuiFormControl-root {
    height: 40px;
    .MuiSelect-root {
      height: 100%;
      .MuiSelect-select {
        height: 100%;
        padding: 0 32px 0 14px !important;
        display: flex;
        align-items: center;
      }
      .MuiSvgIcon-root {
        right: 4px;
      }
      fieldset {
        height: 100%;
        top: 0;
        legend {
          display: none;
        }
      }
    }
  }
  // TextField
  .MuiOutlinedInput-root {
    border-radius: 0;
  }

  // MuiButton-root
  .MuiButton-root {
    height: 40px;
    border-radius: 0;
  }
}

.page-view {
  .h-top {
    height: 80px;
    background-color: #6bbaff;
  }
  .inner {
    padding: 80px 50px;
  }
}

.user-modal {
  width: 100%;
  height: 100%;
  padding: 20px 20px;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  .card {
    padding: 60px 100px;
    border-radius: 10px;
    background-color: #fff;
    position: relative;
  }

  .clear-btn {
    width: 40px;
    height: 40px;
    padding: 8px;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    right: 2px;
    color: #777;
  }
  .clear-btn:hover {
    background-color: transparent;
    color: #6bbaff;
  }

  .checkbox-item {
    color: #777;
    .MuiFormControlLabel-root {
      margin: 0;
    }
  }

  .certified {
    width: 240px;
    height: 40px;
    line-height: 40px;
    border: 1px solid #6bbaff;
    text-align: center;
    color: #6bbaff;
    background-color: #fff;
  }
}

// 로그인
.sign-in {
  .flex-row {
    margin: 0 0 20px;
    .ttl {
      width: 120px;
    }
  }
  .MuiButton-root.signin-btn {
    width: 100%;
    margin: 40px 0;
  }
  .signup-btn {
    margin: 0 auto;
    display: block;
    color: #777;
    .MuiTouchRipple-root {
      display: none;
    }
  }
  .signup-btn:hover {
    background-color: transparent;
    color: #6bbaff;
  }
  .btn-group {
    .MuiButton-root {
      margin: 0 10px;
      color: #777;
      position: relative;
      .MuiTouchRipple-root {
        display: none;
      }
    }
    .MuiButton-root:hover {
      background-color: transparent;
      color: #6bbaff;
    }
    .MuiButton-root:nth-child(1)::after {
      content: "";
      width: 1px;
      height: 16px;
      background-color: #999;
      position: absolute;
      top: 50%;
      right: -10px;
      transform: translate(-50%, -50%);
    }
  }
}

// 회원가입
.sign-up {
  .flex-row {
    margin: 0 0 20px;
    .MuiButton-root {
      width: 240px;
    }
  }
  .alert-txt {
    margin: -10px 0 20px 120px;
  }
  .signup-btn.contained {
    width: 100%;
    margin: 40px 0 0;
  }
}

// 아이디 찾기
.find-id {
  .sub-head {
    margin: 40px 0 20px;
    font-size: 16px;
    text-align: center;
    color: #777;
  }
  .certified-btn,
  .txt {
    width: calc(100% - 120px);
    height: 40px;
    line-height: 40px;
  }
}

// 비밀번호 재설정
.reset-pw {
  .sub-head {
    margin: 40px 0 20px;
    font-size: 16px;
    text-align: center;
    color: #777;
  }
  .certified-btn {
    width: 240px;
  }

  .check-btn {
    width: 100%;
    margin: 40px 0 0;
  }
}

// 내정보
.myinfo {
  .cont-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    .flex-row {
      margin: 0 0 20px;
      .ttl {
        width: 140px;
      }
    }
    .sub-txt {
      color: #777;
      margin: 40px 0 20px;
    }
    .alert-txt {
      width: 380px;
      margin: -10px 0 0;
      padding: 0 0 0 140px;
    }
    .contained {
      width: 380px;
      margin: 40px 0 0;
    }
    .btn-bx {
      width: 380px;
      margin: 10px 0 0;
      text-align: right;
      .with-drawal {
        color: #777;
        display: inline-block;
        .MuiTouchRipple-root {
          display: none;
        }
      }
      .with-drawal:hover {
        background-color: transparent;
        color: #000;
        font-weight: bold;
      }
    }
  }
}

// 결제 내역
.payment-list {
  .search-area {
    margin: 0 0 20px;
    .search-bx {
      .MuiTextField-root {
        width: 240px;
        .MuiInputBase-input {
          padding: 0 40px 0 14px !important;
        }
      }
      .search-btn {
        margin: 0 0 0 -40px;
        height: 40px;
        border-radius: 50%;
        color: #777;
      }
    }
    .MuiFormControl-root {
      width: 120px;
    }
  }

  .list-area {
    padding: 0 60px;
    border: 1px solid #bebebe;
    border-radius: 6px;
    li {
      padding: 30px 20px;
    }
    li:not(:last-child) {
      border-bottom: 1px solid #ccc;
    }
    .status {
      width: 70px;
      height: 70px;
      margin: 0 30px 0 0;
      border-radius: 50%;
      background-color: #bebebe;
      color: #fff;
    }
    .status.ing {
      background-color: #6bbaff;
    }
    .info-txt {
      flex: 1;
    }
    .num,
    .date,
    .price {
      color: #777;
    }
    .type {
      margin: 10px 0;
    }
  }
}

// 회원 탈퇴
.with-drawal {
  .txt-bx {
    color: #777;
    p {
      line-height: 1.8;
    }
  }
  .sub-head {
    margin: 40px 0;
    font-size: 20px;
    text-align: center;
    color: #777;
  }
  .flex-row {
    margin: 0 0 20px;
    .ttl {
      width: 100px;
    }
    .MuiTextField-root {
      width: calc(100% - 100px);
    }
  }
  .contained {
    width: 100%;
    margin: 40px 0 0;
  }
}
