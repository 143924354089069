.document {
  text-align: left;
  p {
    line-height: 2.5;
  }

  h1 {
    margin: 0 0 40px;
    font-size: 21.332px;
    line-height: 2.5;
    word-spacing: 6px;
  }
  h1.head {
    text-align: center;
  }

  .plaintiff-area,
  .defendant-area {
    > p {
      margin: 0 30px 0 0;
      letter-spacing: 20px;
    }
  }
  .plaintiff-area{
    margin: 0 0 20px;
  }

  .sub-ttl {
    margin: 40px 0;
    display: block;
  }

  .price-area {
    margin: 0 0 40px;
  }

  .purpose-area,
  .cause-area {
    margin: 0 0 40px;
    .ttl {
      margin: 0 0 20px;
      display: block;
      font-size: 13.866px;
      text-align: center;
    }
    .list-item {
      display: flex;
      p:first-child {
        margin: 0 4px 0 0;
      }
    }
  }

  .evidence-area,
  .attachment-area {
    margin: 0 0 40px;
    .ttl {
      margin: 0 0 20px;
      display: block;
      font-size: 13.866px;
      text-align: center;
    }
  }

  .date {
    display: block;
    text-align: center;
  }

  .sign {
    margin: 40px 0;
    text-align: right;
    span:nth-child(1) {
      margin: 0 30px 0 0;
    }
    span:nth-child(2) {
      margin: 0 0 0 10px;
    }
  }

  .sign-app {
    margin: 40px 0;
    text-align: right;
    span:nth-child(1) {
      margin: 0 15px 0 0;
    }
    span:nth-child(2) {
      margin: 0 0 0 10px;
    }

    span:nth-child(2) {
      margin: 0 15px 0 0;
    }
  }

  .table-group {
    padding: 0 0 20px;
    overflow-x: auto;
    table {
      border-collapse: collapse;
      
      th {
        padding: 6px 12px;
        text-align: center;
        background-color: rgba(107, 186, 255, 0.5);
        border-top: 1px solid #000;
        border-left: 1px solid #000;
        border-bottom: 1px solid #000;
      }
      th:last-child {
        border-right: 1px solid #000;
      }

      td {
        white-space: nowrap;
        padding: 6px 12px;
        border-left: 1px solid #000;
        border-bottom: 1px solid #000;
        text-align: center;
      }
        td:last-child {
          border-right: 1px solid #000;
        }
      
      
    }
    .bond-table {
      margin: 0 0 40px;
    }

    .payback-table {
      thead tr:nth-child(1){
        th:nth-child(5), th:nth-child(6) {
          border-bottom: none;
        }
      }
      
    }
  }

  // 사실조회 신청서
  .fact-find-preview{
    .plaintiff-area,
    .defendant-area{
      > div:nth-child(1){
        width: 60px;
        margin-right: 40px;
        display: flex;
        justify-content: space-between;
      }
    }
    .plaintiff-area{
      margin: 0;
    }
  }
}

