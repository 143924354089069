.order {
  .h-top {
    height: 80px;
    background-color: #6bbaff;
  }
  .inner {
    max-width: 680px;
    margin: 0 auto;
    padding: 80px 40px;
  }

  .head-area {
    margin: 0 0 60px;
    text-align: center;
    .head {
      font-size: 24px;
      position: relative;
    }
    .head::after {
      content: "";
      width: 50%;
      height: 2px;
      background-color: #bebebe;
      position: absolute;
      bottom: -12px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  .card {
    margin: 0 0 20px;
    padding: 0 20px;
    box-shadow: 0 0 6px 2px #ddd;
    .card-head {
      padding: 14px 0;
      border-bottom: 1px solid #ddd;
      font-weight: bold;
    }
    .card-body {
      padding: 14px 0;
      .fs18 {
        font-size: 18px;
        font-weight: bold;
      }
    }
  }

  .price-card {
    b {
      display: inline-block;
      margin: 0 4px 0 0;
    }
  }

  .account-card {
    .card-head {
      span {
        margin: 0 0 0 20px;
        font-size: 12px;
        font-weight: normal;
      }
    }
    .card-body {
      .MuiFormControl-root:nth-child(1) {
        min-width: 150px;
      }
      .MuiFormControl-root:nth-child(2) {
        width: 120px;
        margin: 0 20px;
      }
      .MuiFormControl-root:nth-child(3) {
        width: 250px;
      }
    }
  }

  //
  .MuiOutlinedInput-root {
    height: 100%;
    border-radius: 0;
    .MuiInputBase-root {
      height: 100%;
    }
    .MuiOutlinedInput-input {
      height: 100%;
    }
    fieldset {
      height: 100%;
    }
  }

  // select
  .MuiFormControl-root {
    height: 40px;
    .MuiSelect-root {
      height: 100%;
      .MuiSelect-select {
        height: 100%;
        padding: 0 32px 0 14px !important;
        display: flex;
        align-items: center;
      }
      .MuiSvgIcon-root {
        right: 4px;
      }
      fieldset {
        height: 100%;
        top: 0;
        legend {
          display: none;
        }
      }
    }
  }

  // term-modal
  .term-modal {
    width: 100%;
    height: 100vh;
    padding: 20px 20px;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    * {
      font-family: "Malgun Gothic";
    }
    .head {
      margin: 0 0 20px;
      font-size: 15px;
      font-weight: bold;
      text-align: center;
    }

    .card {
      width: 600px;
      padding: 40px;
      border-radius: 10px;
      background-color: #fff;
      position: relative;
    }

    .clear-btn {
      width: 40px;
      height: 40px;
      padding: 8px;
      border-radius: 50%;
      position: absolute;
      top: 2px;
      right: 2px;
      color: #777;
    }
    .clear-btn:hover {
      background-color: transparent;
      color: #6bbaff;
    }

    table {
      width: 100%;
      margin: 20px 0;
      border: 1px solid #bebebe;
      border-collapse: collapse;
      th {
        border: 1px solid #bebebe;
        padding: 10px 20px;
      }
      td {
        border: 1px solid #bebebe;
        padding: 10px;
        p:last-child {
        }
      }
      td:last-child {
        text-align: center;
      }
      .refund0 {
        p:nth-child(2) {
          padding: 0 0 0 40px;
        }
      }
    }
    .explanation {
      padding: 0 0 0 20px;
      li:not(:last-child) {
        margin: 0 0 10px;
      }
    }

    // .checkbox-item {
    //   color: #777;
    //   .MuiFormControlLabel-root {
    //     margin: 0;
    //   }
    // }

    // .certified {
    //   width: 240px;
    //   height: 40px;
    //   line-height: 40px;
    //   border: 1px solid #6bbaff;
    //   text-align: center;
    //   color: #6bbaff;
    //   background-color: #fff;
    // }
  }
}

// complete-order
.complete-order {
  text-align: center;
  p:nth-child(1) {
    margin: 0 0 10px;
    font-size: 16px;
  }
  p:nth-child(2) {
    font-size: 16px;
  }

  .show-payment-btn {
    width: 240px;
    height: 50px;
    margin: 40px 0 0;
    border-radius: 0;
    font-size: 16px;
    cursor: pointer;
  }
}
