.cont-bx {
  padding: 10px;
  border-radius: 6px;
  background-color: #eee;
  font-size: 14px;
  > .ttl,
  .ttl-bx {
    margin: 0 0 10px;
  }

  .MuiFormControl-root.select {
    min-width: 100px;
  }
  .MuiFormControl-root.text-field {
    width: 120px;
  }
}

// textarea
.textarea-wrap {
  .MuiFormControl-root {
    width: 100%;
    height: auto !important;
    .MuiInputBase-root {
      height: auto !important;
      padding: 10px 4px;
    }
    fieldset {
      height: 100% !important;
    }
  }
}

.step1 {
  .cont-bx {
    margin: 0 0 20px;
    position: relative;
    .ttl-bx {
      .info-btn {
        margin: 0 0 0 6px;
        padding: 0;
        border-radius: 50%;
        color: #999;
        .MuiSvgIcon-root {
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
      }
      .info-btn.active {
        color: #6bbaff;
      }
    }
    .MuiFormControl-root.select {
      width: 140px;
    }
    .remove-btn {
      position: absolute;
      top: 10px;
      right: 10px;
    }
    .sub-txt {
      margin: 10px 0;
    }
    .info-item-bx {
      .MuiFormGroup-root {
        margin: 0 0 0 20px;
        .MuiFormControlLabel-root {
          align-items: center !important;
          .MuiSvgIcon-root {
            width: 18px;
            height: 18px;
          }
          .MuiTypography-root{
            font-size: 15px;
          }
        }
      }
    }

    > .MuiFormGroup-root {
      .MuiFormControlLabel-root {
        align-items: flex-start !important;
        .MuiTypography-root {
          margin: 2px 0 0;
        }
      }
      .MuiFormControlLabel-root:not(:last-child) {
        margin: 0 0 4px;
      }
    }
    .info-item-bx {
      p {
        width: 120px;
      }
      > .MuiTextField-root{
        width: 300px;
      }
      .input_address{
        width: 300px;
        height: 30px;
        padding: 0 10px;
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 4px;
        background-color: #fff;
        display: flex;
        align-items: center;
        cursor: pointer;
        span{
          color: #aaa;
        }
      }
    }
    .info-item-bx {
      margin: 0 0 10px;
    }
    .tel-item-bx {
      .MuiTextField-root {
        width: 80px;
      }
      .hyphen {
        width: 30px;
        text-align: center;
      }
    }
    .rrNum-item-bx,
    .corporationNum-item-bx {
      .MuiTextField-root {
        width: 135px;
      }
      .hyphen {
        width: 30px;
        text-align: center;
      }
    }
    .cNum-item-bx {
      .input-group{
        width: 300px;
        display: flex;
        align-items: center;
        .MuiTextField-root:nth-child(1){
          width: 72px;
        }
        .MuiTextField-root:nth-child(3){
          width: 48px;
        }
        .MuiTextField-root:nth-child(5){
          width: 120px;
        }
      }
      .hyphen {
        width: 30px;
        text-align: center;
      }
    }
  }
}

.step2 {
  .head-bx {
    position: relative;
    button {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .cont-bx {
    margin: 0 0 20px;
    position: relative;
    > .remove-btn {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
  .ttl-bx {
    position: relative;
    button {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .ttl {
    font-weight: 600;
    span {
      font-weight: normal;
    }
  }
  .border-bx {
    margin: 10px 0 0;
    padding: 10px;
    border: 1px solid #bebebe;
    border-radius: 6px;
    position: relative;
    .remove-btn {
      position: absolute;
      top: 6px;
      right: 6px;
    }
    .MuiFormControl-root.select, .date-picker{
      margin: 3px 0;
    }
    .checkbox-item {
      position: relative;
      .MuiSelect-root {
        width: 120px;
      }
      .btn-24 {
        position: absolute;
        top: 0;
        right: 10px;
        transform: translate(100%, 0);
      }
      > .remove-btn {
        position: absolute;
        top: 0;
        right: 24px;
      }
    }
  }

  .joint-guarantor {
    position: relative;
    .add-btn {
      position: absolute;
      top: 0;
      right: 10px;
      z-index: 2;
    }
    .radio-item {
      margin: 10px 0 0;
    }
  }

  .radio-item,
  .checkbox-item {
    margin: 0 0 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    cursor: pointer;
  }
  .radio-item:hover,
  .checkbox-item:hover {
    .MuiButtonBase-root {
      background-color: rgba(107, 186, 255, 0.1);
    }
  }
  .input-price {
    width: 140px;
    margin: 0 2px 0 0;
  }
  .input-percentage {
    width: 60px;
    margin: 0 2px 0 0;
  }
  .w100 {
    width: 100px;
  }
  .w140 {
    width: 140px;
  }
  .w180 {
    width: 180px;
  }
  .w200 {
    width: 200px;
  }
  .w240 {
    width: 240px;
  }
  .mt_4 {
    margin-top: 4px;
  }
  .mr_10 {
    margin-right: 10px;
  }
  .mb_4 {
    margin-bottom: 4px;
  }
  .ml_4 {
    margin-left: 4px;
  }
  .radio-sub-bx {
    margin: -5px 0 10px;
    padding: 0 0 0 25px;
  }
  .radio-item-sub-txt {
    width: 100%;
    padding: 0 0 0 14px;
  }

  .btn-24 {
    width: 24px;
    height: 24px;
    .MuiSvgIcon-root {
      width: 20px;
      height: 20px;
    }
  }
  > div {
    > .contained {
      margin: -10px 0 20px;
    }
  }

  .MuiSelect-root {
    min-width: 100px;
    height: 30px;
    background-color: #fff;
    .MuiSelect-select {
      padding: 0 20px 0 10px !important;
    }
    .MuiSvgIcon-root {
      width: 20px;
      height: 20px;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
    }
    fieldset {
      height: 30px;
      top: 0;
      legend {
        display: none;
      }
    }
  }

  // .comment-area{
  //   .add-btn{
  //     right: 40px;
  //   }
  // }

  // 대여금, 약정금
  .lend-price {
    position: relative;
    .answer-area.item2 {
      position: relative;
      .add-btn {
        position: absolute;
        top: -31px;
        right: 0;
      }
    }
    .item5,
    .item6,
    .item7 {
      .radio-item {
        margin: 0;
      }
    }
  }

  // 양수금
  .assignment-price {
    .answer-area.item3 {
      position: relative;
      button {
        position: absolute;
        top: -31px;
        right: 0;
      }
    }
    .item7,
    .item8,
    .item9,
    .item10 {
      .radio-item {
        margin: 0;
      }
    }
  }

  // 매매대금
  .trading-price {
    .cont-bx {
      position: relative;
      .answer-area.item5 {
        .input-text {
          width: 100px;
          margin: 0 10px 0 0;
        }
      }
      .answer-area.item7 {
        margin: 10px 0 0;
        .remove-btn {
          margin: 0 0 0 14px;
        }
      }
    }
  }

  // 용역대금
  .service-price {
    .cont-bx {
      position: relative;
      .answer-area.item9 {
        .input-text {
          width: 100px;
          margin: 0 10px 0 0;
        }
      }
    }
  }

  // 계약금반환(매매대금)
  .contract-trading-price {
    .cont-bx {
      .answer-area.item4 {
        margin: 10px 0 0;
      }
      .answer-area.item5 {
        margin: 10px 0 0;
      }
      .answer-area.item6 {
        .radio-sub-bx {
          .MuiButton-root {
            margin: 0 0 0 10px;
          }
        }
      }
      .answer-area.item7-1 {
        p {
          display: inline-block;
        }
      }
    }
  }

  // 임대차보증금
  .deposit {
    .answer-area__ttl{
      &.land {
        margin: 0 0 6px;
      }
      &.building {
        margin: 20px 0 6px;
      }
    }
    .answer-area__land {
      + .answer-area__land{
        margin-top: 4px;
      }
      > div:nth-child(1){
        width: 240px;
      }
      .select{
        margin: 0 6px;
      }
      > div:nth-child(3){
        width: 60px;
      }
    }
    .answer-area__building {
      + .answer-area__building{
        margin-top: 4px;
      }
      .MuiFormControl-root:nth-child(1) {
        width: 240px;
        margin: 0 10px 0 0;
      }

    }

    .answer7{
      .date-picker{
        margin: 2px 0;
      }
    }
  }

  // 용역대금반환
  .contract-service-price{
    .answer4{
      .row-bx + .row-bx{
        margin-top: 5px;
      }
    }
    .answer7-1, .answer8{
      .date-picker{
        margin: 2px 0;
      }
    }
  }

  // 손해배상
  .loss {
    .answer2{
      .MuiFormControl-root + .MuiFormControl-root{
        margin-left: 4px;
      }
    }
    .answer-area.item3 {
      .select{
        + span{
          margin-left: 4px;
          display: block;
        }
      }
      .MuiSelect-select {
        min-width: 140px;
      }
      .textarea-wrap {
        margin: 10px 0 0;
      }
    }
    .ttl-bx {
      display: flex;
      align-items: center;
      button {
        margin: 0 0 0 10px;
        position: relative;
      }
    }
    .answer-area.item5 {
      .remove-btn {
        margin: 0 0 10px 10px;
      }
    }
  }

  // 사건명
  .incident {
    .select {
      margin: 0 10px 10px 0;
    }
    .MuiSelect-root.w70 {
      width: 70px;
      min-width: 0;
    }
    .MuiTextField-root {
      margin: 0 10px 10px 0;
    }
    > p{
      margin: 0 10px 0 0;
      line-height: 30px;
    }
  }

  // 집행권원의 종류
  .execution {
    .select.w70 {
      width: 70px;
      min-width: 0;
    }
    > .select {
      width: 240px;
      margin: 0 0 10px;
      .MuiSvgIcon-root {
        top: 50%;
        transform: translate(0, -50%);
      }
    }
    .item.most-item {
      .select {
        margin: 0 10px 10px 0;
      }
    }
    .item.option7 {
      .MuiTextField-root.law-firm {
        width: 200px;
        margin: 0 5px;
      }
      .select {
        margin: 0 4px 0 14px;
      }
      .MuiTextField-root.num {
        margin: 0 5px;
      }
      .flex-row:first-child{
        margin: 0 0 10px;
      }
      .flex-row:last-child {
        p:last-child {
          margin: 0 0 0 8px;
        }
      }
    }
    .item.option8 {
      .MuiTextField-root {
        width: 100%;
      }
    }

  }

  // 사실조회 신청서
  .fact-find {
    .answer-area.item1 {
      > .flex.ai-start{
        .item-ttl span{
          line-height: 30px;
        }
      }
      > .flex-row {
        margin: 0 0 10px;
      }
      .item-ttl {
        width: 62px;
        margin: 0 10px 0 0;
      }
      .incident {
        width: calc(100% - 72px);
      }
    }

    .answer-area.item2 {
      .MuiTextField-root {
        width: 80px;
      }
      span {
        height: 30px;
        line-height: 30px;
        margin: 0 5px;
        display: inline-block;
      }
    }
  }

  // 부동산강제경매신청서
  .auction {
    .answer-area.item3 {
      .item-ttl-row {
        margin: 10px 0 0;
      }
      .list-item {
        margin: 5px 0 0;
        display: flex;
        p{
          line-height: 30px;
        }
        div {
          display: flex;
          button {
            height: 30px;
            margin: 0 0 0 10px;
          }
        }
      }
    }
    .answer-area.item4 {
      .MuiSelect-root {
        width: 370px;
      }
      .flex-row {
        margin: 0 0 10px;
        .item-ttl {
          width: 40px;
        }
      }
      .btn-24 {
        margin: 0 0 0 20px;
        border-radius: 50%;
        background-color: #6bbaff;
        color: #fff;
      }
      .address-row {
        .MuiTextField-root:nth-child(2) {
          margin: 0 5px 0 0;
          flex: 1;
        }
        .MuiTextField-root:nth-child(3) {
          margin: 0 0 0 5px;
          flex: 1;
        }
      }
    }

    .modal {
      .MuiSelect-root {
        height: 36px;
        fieldset {
          height: 36px;
        }
      }
      .MuiTextField-root {
        height: 36px;
        .MuiInputBase-root {
          height: 36px;
        }
        fieldset {
          height: 36px;
        }
      }
    }
  }

  // 채권압류
  .bond-seizure {
    .ttl-bx {
      display: flex;
      align-items: center;
      button {
        margin: 0 0 0 20px;
        position: relative;
      }
    }

    .MuiPaper-root {
      margin: 0 0 10px;
      box-shadow: none;
      table {
        border: 1px solid #bebebe;
        th {
          font-size: 13px;
          padding: 0 10px;
          text-align: center;
        }
        td {
          padding: 5px;
          border: none;
          .percentage {
            width: 74px;
          }
        }
        th:first-child,
        td:first-child {
          padding: 0 5px 0 10px;
        }
      }
    }
    .btn-24 {
      border-radius: 50%;
      background-color: #6bbaff;
      color: #fff;
    }

    .answer-area.item4 {
      margin: 10px 0 0;
      > div:first-child {
        margin: 15px 0 0;
      }
      > div:not(:first-child) {
        margin: 5px 0 0;
        .select { margin-right: 10px; }
        .flex-row{
          + .flex-row{
            margin-top: 5px;
          }
        }
        .row-ttl {
          min-width: 60px;
          margin: 0 10px 0 0;
        }
        .radio-item {
          margin: 0 0 0 10px;
        }
      }
      .MuiSelect-root {
        margin: 0 10px 0 0;
        width: 245px;
      }
      .btn-24 {
        margin: 0 0 0 10px;
      }
      .address-row {
        .MuiFormControl-root {
          width: 240px;
        }
      }
    }

    .answer-area.item6 {
      .radio-item:first-child {
        margin: 0;
        align-items: flex-start;
        .incident {
          width: calc(100% - 30px);
          display: inline-block;
          span {
            line-height: 36px;
          }
          .input-price {
            margin: 0 2px 0 0;
          }
        }
      }
    }
  }

  // 재산조회신청서
  .asset-find {
    .answer-area.item6 {
      .checkbox-item {
        margin: 0 0 10px;
        display: inline-flex;
      }
      .checkbox-item:nth-child(1){
        width: calc(100% * 1/3);
      }
      .checkbox-item:nth-child(2){
        width: calc(100% * 1/3);
      }
      .checkbox-item:nth-child(3){
        width: calc(100% * 1/3);
      }
      .checkbox-item:nth-child(4){
        width: calc(100% * 1/3);
      }
      .checkbox-item:nth-child(5){
        width: calc(100% * 2/3);
      }
      .checkbox-item:nth-child(6){
        width: calc(100% * 2/3);
      }
    }
    .answer-area.item7 {
      display: flex;
      div + div{
        margin-left: 10px;
      }
      .MuiFormControl-root:nth-child(1){
        width: 140px;
      }
      .MuiFormControl-root:nth-child(2){
        width: 120px;
      }
      .MuiFormControl-root:nth-child(3){
        flex: 1;
      }
    }
  }
}

.step3-procedure {
  .procedure-card {
    min-height: 150px;
    .ttl-btn {
      width: 100px;
      margin: 0 10px 0 0;
      padding: 10px;
      border-radius: 6px 0 0 6px;
      background-color: #adadad;
      color: #fff;
      .MuiButton-label {
        flex-direction: column;
        font-size: 15px;
      }
    }
    .ttl-btn.selected {
      background-color: #6bbaff;
    }

    .cont-bx {
      padding: 10px;
      border-radius: 0 6px 6px 0;
      background-color: #fff;
      .cont-ttl {
        margin: 0 0 20px;
        font-size: 18px;
      }
      .txt {
        margin: 0 0 6px;
        font-size: 13px;
      }
      .fees-bx {
        margin: auto 0 0;
        p:nth-child(1) {
          margin: 0 6px 0 0;
          font-size: 13px;
        }
        p:nth-child(2) {
          font-size: 11px;
        }
      }
    }
  }
  .procedure-card:first-child {
    margin: 0 0 10px;
  }
}

.step3-court {
  label {
    display: flex;
    align-items: center;
  }
}

.step4-evidence,
.step4-attach {
  margin: 10px 0 0;
  position: relative;

  .remove-btn {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .item-bx {
    .ttl {
      width: 120px;
    }
    .MuiTextField-root {
      width: 240px;
    }
  }
  .item-bx:not(:nth-child(2)) {
    margin: 10px 0 0;
  }
  .item-bx.file-row-bx {
    .file-name {
      margin: 0 6px 0 0;
    }
    label {
      height: 30px;
      padding: 0 10px;
      border-radius: 4px;
      background-color: #6bbaff;
      color: #fff;
      cursor: pointer;
    }
    .hide-input {
      // display: none;
    }
  }
}

.step5-check {
  .item-bx {
    padding: 10px;
    background-color: #e0e0e0;
    border-radius: 6px;
    .icon-ttl {
      width: 120px;
      padding: 14px 10px;
      border-right: 1px solid #444;
      img {
        width: 70px;
        margin: 0 auto 20px;
      }
    }
    .contents {
      padding: 10px;
      .row-bx {
        p {
          margin: 0 0 6px;
        }
      }
    }
  }
  .item-bx:nth-child(1) {
    img {
      width: 56px;
      margin: 0 auto 20px;
    }
  }
  .item-bx:not(:last-child) {
    margin: 0 0 10px;
  }
}

.step6-service {
  .item-bx {
    width: calc(50% - 5px);
    .card {
      padding: 30px;
      background-color: #fff;
      flex: 1;
      .card-ttl-bx {
        height: 30px;
        margin: 0 0 20px;
        background-color: #6bbaff;
        color: #fff;
        border-radius: 15px;
      }
      img {
        width: auto;
        height: 70px;
        margin: 0 auto 30px;
      }
      .price {
        margin: 45px 0 50px;
        b {
          font-size: 40px;
        }
      }
      .color-6bbaff {
        font-size: 12px;
      }
    }
    button {
      width: 100%;
      height: 40px;
      margin: 20px 0 0;
      background-color: #adadad;
      color: #fff;
      font-size: 18px;
    }
  }
}
