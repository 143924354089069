@import url('https://fonts.googleapis.com/css2?family=Nanum+Myeongjo&display=swap');

.write-document {
  width: 100%;
  height: 100%;
  padding: 20px 20px;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  z-index: 5;

  .modal-bx {
    height: 100%;
    max-height: 100vh;
    padding: 20px 20px 40px;
    display: inline-block;
    background-color: #fff;
    position: relative;

    > .close-btn {
      padding: 7px;
      border-radius: 50%;
      color: #222;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      .MuiSvgIcon-root {
        width: 26px;
        height: 26px;
      }
    }
  }

  .step-area {
    margin: 0 0 40px;
    .step-bx {
      flex: 1;
      position: relative;
      .line {
        width: 100%;
        height: 20px;
        background-color: #e1f1ff;
        position: absolute;
        top: 47px;
        left: 0;
        transform: translate(-50%, -50%);
      }
      .icon-btn {
        width: 94px;
        height: 94px;
        margin: 0 auto 10px;
        border-radius: 50%;
        background-color: #e1f1ff;
        color: #fff;
        position: relative;
        z-index: 6;
        img {
          width: 50px;
        }
      }
      .ttl {
      }
    }
    .step-bx:first-child {
      .line {
        display: none;
      }
    }
    .step-bx.active {
      .line {
        background-color: #6bbaff;
      }
      .icon-btn {
        background-color: #6bbaff;
      }
    }
  }

  .preview-txt {
    font-size: 24px;
  }

  .write-document-area {
    height: calc(100% - 284px);
    margin: 20px 0;
    position: relative;
    .write-area {
      width: 570.11px;
      height: 100%;
      margin: 0 20px 0 0;
      border: 1px solid #d6d6d6;
      border-radius: 6px;
      overflow: auto;
      .write {
        width: 100%;
        text-align: left;
        > div {
          padding: 10px;
          .head {
            margin: 0 0 10px;
            display: block;
            font-size: 20px;
          }
          hr {
            margin: 20px 0;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
          }
        }
        .sub-txt {
          font-size: 12px;
          color: grey;
        }

        .step1 .defendant-add-btn {
          width: 100px;
          margin: -10px 0 0;
        }
        .step2 .bond-add-btn {
          width: 100px;
          margin: 10px 0 0;
        }
        .step4 {
          .sub-txt {
            margin: 6px 0 0;
          }
          .evidence-add-btn,
          .attach-add-btn {
            margin: 10px 0 0;
          }
        }
      }
    }

    .document-area {
      border: 1px solid #d6d6d6;
      border-radius: 6px;
      overflow: auto;
      .document {
        width: 551.111px;
        min-height: 779.555px;
        padding: 117.712px 52.355px 78.735px 52.355px;
        font-size: 12.8px;
      }

      .loss-preview {
        p.h40 {
          height: 40px;
        }
      }
      .loss-title {
        display: flex;
        p:first-child {
          margin: 0 4px 0 0;
        }
        p:first-child.bold {
          font-family: "S-CoreDream-6Bold";
        }
        p:nth-child(2) {
          flex: 1;
          word-break: break-all;
        }
      }
      .loss-content {
        display: flex;
        p:first-child {
          margin: 0 4px 0 0;
          white-space: nowrap;
        }
      }

      .asset-find-preview {
        .table {
          border: 1px solid #222;
          p,
          span {
            line-height: 2;
          }
        }
        .row-item {
          display: flex;
          .ttl {
            width: 102px;
            padding: 10px;
            border-right: 1px solid #222;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center; 
          }
          p:not(.ttl), span {
            font-size: 11px;
          }
          .cont {
            width: 100%;
            flex: 1;
            padding: 10px;
            .checkbox-group {
              > .checkbox-item {
                width: 50%;
              }
            }
            .checkbox-item {
              .MuiSvgIcon-root {
                width: 18px;
                height: 18px;
                margin: 0 4px 0 0;
              }
            }
          }
        }
        .row-item:not(:last-child) {
          border-bottom: 1px solid #222;
        }
        .creditor-name,
        .name-address {
          width: 100%;
          flex: 1;
        }
        .rr-num {
          width: 214px;
          margin: 0 0 0 10px;
        }
        .signature-item {
          .cont {
            padding: 15px 10px 10px;
          }
          p:first-child {
            text-align: center;
          }
          .flex-center {
            margin: 15px 0;
            span:nth-child(2) {
              min-width: 50px;
              margin: 0 20px;
              text-align: center;
            }
          }
          p:last-child {
            text-align: right;
          }
        }
      }

      .relation-table{
        //width: 586px;
        width: 100%;
        table {
          //width: 100%;
          border-collapse: collapse;
          th, td {
            border: 1px solid #222;
            padding: 4px 10px;
          }
          th{
            text-align: center;
          }
          th:nth-child(2){
            width: 100px;
          }
          th:nth-child(3){
            width: 60px;
          }
        }
        
      }
      .relation-table *{
        //font-family: 'Nanum Myeongjo', serif;
      }
    }
  }

  .btn-group {
    button {
      width: 140px;
      height: 40px;
      margin: 0 10px;
      border-radius: 6px;
    }
    .prev-btn {
      border: 1px solid #e5e5e5;
      color: #adadad;
    }
    // .next-btn {
    //   background-color: #6bbaff;
    //   color: #fff;
    // }
  }

}

.modal-container {
  width: 100%;
  height: 100vh;
  padding: 40px 80px;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  .modal {
    margin: auto;
    background-color: #fff;

    .top-area {
      border-bottom: 1px solid #bebebe;
      padding: 15px 20px;
      font-size: 16px;
    }

    .middle-area {
      height: calc(100% - 123px);
      padding: 20px;
      overflow-x: auto;
      .item {
        display: flex;
        .ttl {
          width: 80px;
          line-height: 30px;
        }
        .MuiFormControl-root {
          width: 300px;
        }
        .MuiSelect-root {
          width: 300px;
        }
        span {
          line-height: 30px;
        }
      }
      .item:not(:last-child) {
        margin: 0 0 20px;
      }

      .edit-floor {
        .item {
          margin: 0;
        }
        .floor-row {
          margin: 10px 0 0;
          padding: 0 0 0 80px;
          span {
            margin: 0 20px 0 2px;
          }
        }
      }
    }

    .bottom-area {
      padding: 10px 20px 20px;
      text-align: right;
      button {
        margin: 0 0 0 10px;
        width: 60px;
      }
    }
  }
}

.modal-container.edit-building {
  .modal {
    height: 100%;
  }
}
.modal-container.edit-set-building {
  > .modal{
    height: 80%;
  }
  .middle-area {
    align-items: flex-start;
    .left{
      .row-item{
        .btn-24{
          margin: 0 0 0 10px;
        }
      }
      .row-item:not(:last-child){
        margin: 0 0 20px;
      }
      .list-item{
        margin: 10px 0 0;
        display: flex;
        .MuiButton-root{
          width: 50px;
          height: 30px;
          margin: 0 0 0 10px;
        }
      }
    }

    .right{
      margin: 0 0 20px 40px;
    }
    // > .flex-row {
    //   .add-btn {
    //     margin: 0 0 0 10px;
    //   }
    // }
    // > .flex-row:not(:last-child) {
    //   margin: 0 0 20px;
    // }
    // > div:first-child {
    //   .item:last-child {
    //     margin: 0 0 0 40px;
    //   }
    // }
    // > div:nth-child(2) {
    //   > div:nth-child(2),
    //   > div:nth-child(3) {
    //     margin: 0 0 0 40px;
    //   }
    // }
    // .sub-head {
    //   margin: 40px 0 20px;
    //   font-size: 16px;
    //   font-weight: 600;
    // }
  }
}
.modal-container.edit-set-building-sub {
  .modal {
    min-width: 420px;
  }
}
