.faq{
  .cont-area{
    max-width: 620px;
    margin: 0 auto;
    
    .MuiAccordion-root{
      min-height: 50px;
      .MuiAccordionSummary-root{
        height: 50px;
        font-weight: bold;
      }
      .MuiAccordionDetails-root{
        padding: 15px;
      }
    }    
  }
}